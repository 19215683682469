import { usePage, Link } from '@inertiajs/react';
import FooterNavLink from '@/Components/FooterNavLink';
import TimeZoneSetter from '@/Components/TimeZoneSetter';
import VersionInfo from '@/Components/VersionInfo';

export default function Main({ auth, children }) {

    const { build_version } = usePage().props;
    const { country } = usePage().props;

    var l = 0;
    if(auth.user) {
        l = auth.user.l;
    }

    const isSendPage = window.location.pathname.includes('/ipadala') || window.location.pathname.includes('/l-edit');    

    return (
        <div className="bg-white text-black">
        <div className="relative min-h-screen flex flex-col items-center justify-start selection:bg-pink selection:text-white">
            <div className="relative w-full max-w-[700px] px-1 md:px-0">
                <header className="items-center pt-[8px] md:pt-[8px] flex">
                    <div className="flex-2">
                        <h1>
                                <Link
                                    href={route('home')}
                                    className="text-[22px] tracking-[0.5px] rounded-md text-pink p-[15px] pl-[3px] pr-[3px] md:pl-[2px] ring-1 ring-transparent transition hover:pink/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    liham ng puso
                                </Link>                                                                   
                        </h1>
                    </div>
                    <nav className="text-[12px] flex flex-1 justify-end mr-[3px]">
                            <>
                                <Link
                                    href={route('letter.send')}
                                    className="rounded-md text-center box-content pt-[4px] pl-[4px] pr-[4px] md:text-center leading-[1.45em] mr-[2px] w-[50px] md:w-[75px] md:mr-[4px] text-black ring-1 ring-transparent transition hover:text-black/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    ipadala
                                </Link>                            
                                <Link
                                    href={route('home')}
                                    className="rounded-md text-center box-content pt-[4px] pl-[4px] pr-[4px] md:text-center leading-[1.45em] mr-[2px] w-[50px] md:w-[75px] md:mr-[4px] text-black ring-1 ring-transparent transition hover:text-black/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    home
                                </Link>
                            </>                                
                    </nav>
                </header>

                <main className="pt-[16px] px-[30px] w-full">
                    <div className="w-full max-w-[500px] text-left mx-auto">
                        {children}
                    </div>
                </main>

                <footer className={`pt-16 text-center text-sm text-black dark:text-white/70 ${isSendPage ? 'hidden' : ''}`}>
                    {/*

                        logged out:
                        - about
                        - login
                        terms of use
                        privacy policy
                        - search
                        - feedback

                        normal users (mod_level = 0):
                        - about
                        + logout
                        + bookmarks
                        terms of use
                        privacy policy
                        - search
                        - feedback
                        
                        mod users (mod_level > 1):
                        - about
                        + logout
                        + bookmarks
                        queue
                        popular
                        block
                        comments
                        mod letters
                        - search
                        - feedback
                    */}

                    <FooterNavLink
                        href={route('about')}
                        >
                        tungkol
                    </FooterNavLink>


                    {auth.user ? (
                            <>
                            <FooterNavLink
                                href={route('logout')}
                                method="post"
                                as="button"
                                >
                                    logout
                            </FooterNavLink>

                            <FooterNavLink
                                    href={route('bookmarks')}
                                    >
                                    bookmarks
                            </FooterNavLink>   

                            {l === 0 && (
                                <>
                                <FooterNavLink
                                    href={route('terms')}
                                    >
                                terms of use
                                </FooterNavLink>
                                <FooterNavLink
                                    href={route('privacy')}
                                >
                                    privacy policy
                                </FooterNavLink>
                                </>
                            )}

                            {l > 0 && (
                                <>
                                <FooterNavLink
                                    href={route('queue.index')}
                                    >
                                    queue
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('popular.index')}
                                    >
                                    popular
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('block')}
                                    >
                                    block
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('comments')}
                                    >
                                    comments
                                </FooterNavLink>     

                                <br />

                                <FooterNavLink
                                    href={route('mod-letters')}
                                    >
                                    mod letters
                                </FooterNavLink>     

                                </>
                            )}


                            {l == 2 && (
                                <>
                                <FooterNavLink
                                    href={route('activity.index')}
                                    >
                                    activity
                                </FooterNavLink>     

                                <FooterNavLink
                                    href={route('require-email.index')}
                                    >
                                    require email
                                </FooterNavLink>     

                                </>
                            )}
                            </>                                    
                        ) : (
                            <>

                                {/*
                                <FooterNavLink
                                    href={route('login')}
                                >
                                    mag-login
                                </FooterNavLink>
                                */}

                                <FooterNavLink
                                    href={route('terms')}
                                >
                                    mga tuntunin ng paggamit
                                </FooterNavLink>
                                <FooterNavLink
                                    href={route('privacy')}
                                >
                                    patakaran sa pagkapribado
                                </FooterNavLink>
                            </>
                        )}

                        <FooterNavLink
                            href={route('search.index')}
                        >
                            maghanap
                        </FooterNavLink>

                        <FooterNavLink
                            href={route('feedback.index')}
                        >
                            puna
                        </FooterNavLink>


                </footer>
                <VersionInfo hide={isSendPage}></VersionInfo>

                {!isSendPage && (
                    <>
                    <p className="text-center text-gray-300 font-light grayscale"><a href="https://x.com/search?q=lihamngpuso&f=live" target="_blank">#lihamngpuso </a><br />
                        <a href="https://x.com/search?q=lihamngpuso&f=live" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 462.799" className="w-[15px] inline-block fill-gray-300">
                                <path fillRule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/>
                            </svg>           
                        </a>                
                    </p>
                    </>
                )}

            </div>
        </div>
        <TimeZoneSetter />
    </div>
    );
}
